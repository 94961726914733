import type { HTTPError } from "ky";

/**
 * Reports a repo error to our error reporting tool
 * while preserving and providing as much context
 * as possible about the failure
 *
 * @param err HTTPError or Sub class of HTTPError
 */
export async function buildRepoErrorContext(err: HTTPError) {
  return {
    context: {
      response: {
        bodyUsed: err.response.bodyUsed,
        body: await err.response.text(),
        ok: err.response.ok,
        redirected: err.response.redirected,
        status: err.response.status,
        type: err.response.type,
        url: err.response.url,
      },
      request: {
        bodyUsed: err.request.bodyUsed,
        cache: err.request.cache,
        method: err.request.mode,
        mode: err.request.mode,
        redirect: err.request.redirect,
        referrer: err.request.referrer,
        referrerPolicy: err.request.referrerPolicy,
        signal: JSON.stringify({
          aborted: err.request.signal.aborted,
          onabort: err.request.signal.onabort,
        }),
        url: err.request.url,
      },
    },
  };
}
