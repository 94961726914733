import type {
  IAppRepoFetchColorsetContract,
  IAppRepoFetchColorsetRequest,
  IAppRepoFetchColorsetResponse,
} from "./types";
import { HTTPError, type ResponsePromise } from "ky";
import { http, repoBuildAndReportError } from "@/core/repo-utils";

const repoName = "appRepoFetchColorset";

export async function appRepoFetchColorset(
  request: IAppRepoFetchColorsetRequest,
): Promise<IAppRepoFetchColorsetResponse | undefined> {
  let response: IAppRepoFetchColorsetContract;
  let rawResponse: ResponsePromise | undefined;

  try {
    const rawResponse = await http().get(
      `storefront/colorset-for-business/${request.businessId}`,
    );

    response = await rawResponse.json();

    return {
      primary: response.primary,
      onPrimary: response.on_primary,
      primaryHover: response.primary_hover,
      secondary: response.secondary,
      onSecondary: response.on_secondary,
      secondaryHover: response.secondary_hover,
    };
  } catch (err) {
    if (err instanceof HTTPError && err.response.status === 404) {
      return undefined;
    }

    throw await repoBuildAndReportError(repoName, rawResponse, err);
  }
}
