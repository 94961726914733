<script setup lang="ts">
import { RouterView, useRoute, useRouter } from "vue-router";
import { useBusinessStore, useUiStore, useBasketStore } from "@/stores";
import { onMounted, ref } from "vue";
import { AppLoadingView } from "@/views/app-loading";
import { ErrorView } from "@/views/error";
import { initMixpanel } from "@/core/tracking";
import { report } from "@chatfood/bug-reporter";
import { RepoKnownError } from "@/core/repo-utils";
import { AutoDiscountAppliedModal } from "@/components/auto-discount-applied-modal";
import { useBootstrap } from "@/composables/use-bootstrap";

const businessStore = useBusinessStore();
const uiStore = useUiStore();
const basketStore = useBasketStore();

const route = useRoute();
const router = useRouter();

const isDiscountAppliedModalVisible = ref(false);
basketStore.$onAction(({ name }) => {
  if (name === "applyAutomaticDiscount") {
    isDiscountAppliedModalVisible.value = true;
  }
});

const { handle, handleWithoutSpot } = useBootstrap();

uiStore.markViewAsLoading();

initMixpanel();

onMounted(async () => {
  await router.isReady();

  const outletSlug =
    typeof route.params.outletSlug === "string"
      ? route.params.outletSlug
      : route.params.outletSlug?.[0];

  if (!outletSlug) {
    uiStore.markViewAsError("outlet-not-found");
    return
  }

  const spotId =
    (typeof route.params.spotId === "string"
      ? route.params.spotId
      : route.params.spotId?.[0]) ?? undefined;

  try {
    if (spotId) {
      await handle(outletSlug, spotId);
    } else {
      await handleWithoutSpot(outletSlug);
    }

    uiStore.markViewAsReady();
  } catch (error: any) {
    report(error);

    const isRepoError = error instanceof RepoKnownError;

    if (isRepoError && error.code === "outlet_not_found") {
      uiStore.markViewAsError("outlet-not-found");
    } else if (isRepoError && error.code === "spot_not_found") {
      uiStore.markViewAsError("spot-not-found");
    } else if (isRepoError && error.code === "business_not_found") {
      uiStore.markViewAsError("business-not-found");
    } else {
      uiStore.markViewAsError("internal-error");
    }
  }
});
</script>

<template>
  <div>
    <AppLoadingView v-if="uiStore.viewState === 'loading'" />
    <ErrorView
      v-else-if="uiStore.viewState === 'error'"
      :status="uiStore.errorStatus ?? 'internal-error'"
      :message="uiStore.errorMessage"
    />
    <RouterView v-else />
    <div v-if="basketStore.discount">
      <AutoDiscountAppliedModal
        v-model="isDiscountAppliedModalVisible"
        :amount="basketStore.discount.amount"
        :currency="businessStore.currency"
        :max-cap="basketStore.discount.maxDiscount"
        :type="basketStore.discount.unit"
        @close="isDiscountAppliedModalVisible = false"
      />
    </div>
  </div>
</template>

<style>
body {
  background-color: var(--color-base-95);
  background: linear-gradient(
    180deg,
    var(--color-base-100) 0%,
    var(--color-base-95) 50px
  );
  background-attachment: fixed;
}
</style>
