/**
 * Represents an error that took place during
 * transformation or processing of the response data
 */
export class RepoProcessError extends Error {
  constructor(repoName: string, err: any) {
    super(err.message);
    this.name = `RepoProcessError::${err.name}`;
    this.message = `${repoName} - ${this.message} - An error occurred while processing the response`;
    this.stack = err.stack;
  }
}
