/**
 * Represents an unknown error which may be caused
 * malformed data being returned
 */
export class RepoUnknownError extends Error {
  constructor(repoName: string, error: any) {
    super(error.message);
    const errorName = `RepoUnknownError::${this.name}`;
    this.message = `${repoName} - ${this.message}`;
    this.stack = error.stack ? error.stack.replace(this.name, errorName) : "";
    this.name = errorName;
  }
}
