import { defineStore } from "pinia";
import type { IBusinessStore } from "./types";

export const businessId = "business";

export const useBusinessStore = defineStore(businessId, {
  state: (): IBusinessStore => ({
    id: "",
    name: "",
    slug: "",
    locale: "",
    currency: "",
    defaultMessagingChannel: "sms",
    isMenuGridDefault: false,
    country: "",
    logo: undefined,
    coverImage: undefined,
    gatewayConfig: {
      id: "",
      gatewayType: -1,
      publishableKey: "",
      isApplePayEnabled: false,
    },
  }),

  actions: {
    setBusiness(newState: IBusinessStore) {
      this.$patch(newState);
    },
  },
});
