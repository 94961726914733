<script setup lang="ts">
type IAutoDiscountAppliedModalProps = {
  modelValue?: boolean;
  amount: number;
  currency: ICurrency;
  type: "percentage" | "fixed";
  maxCap?: number;
}
import { css } from './css'
import { OrgIconModal, AtomButton, AtomText } from '@chatfood/design-system'
import { toMoney } from '@chatfood/core-utils'
import { computed } from 'vue'
import { t } from '@/i18n'
const props = withDefaults(defineProps<IAutoDiscountAppliedModalProps>(), {
  modelValue: false,
});
const emits = defineEmits<{
  (e: "close", event: MouseEvent): void;
  (e: "update:model-value", value: boolean): void;
}>();
const isFixed = computed(() => {
  return props.type === "fixed";
});
const subtitle = computed(() => {
  if (isFixed.value) {
    return t("component.auto_discount_applied_modal.message_for_fixed", {
      amount: toMoney(props.amount, props.currency),
    });
  }
  return t("component.auto_discount_applied_modal.message_for_percentage", {
    amount: props.amount,
  });
});
const cappedAtMessage = computed(() => {
  if (!isFixed.value && props.maxCap && props.maxCap > 0) {
    return t("component.auto_discount_applied_modal.message_for_max_discount", {
      amount: toMoney(props.maxCap, props.currency),
    });
  }
  return undefined;
});
</script>

<template>
  <OrgIconModal
    :model-value="modelValue"
    @update:model-value="(val) => emits('update:model-value', val)"
    @close="emits('close', $event)"
    :class="css()"
    :primaryText="t('component.auto_discount_applied_modal.title')"
    :secondaryText="subtitle"
    iconName="discount"
    iconColor="color-mint"
  >
    <AtomText
      :class="css('capped-message')"
      type="small-bold"
      color="color-canary"
      tag="div"
      v-if="cappedAtMessage"
    >
      {{ cappedAtMessage }}
    </AtomText>
    <AtomButton size="medium" wide @click="emits('close', $event)">
      {{ t("component.auto_discount_applied_modal.acknowledge_cta") }}
    </AtomButton>
  </OrgIconModal>
</template>

<style lang="scss" scoped>
.auto-discount-applied-modal {
  &__capped-message {
    margin-bottom: var(--space-21);
  }
}
</style>
