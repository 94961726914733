import { computed, ref, unref } from "vue";
import { i18n } from "@chatfood/core-utils";
import enLanguageSet from "./languages/en-US.json";

type SafeKey<T> = T extends string | number ? `${T}` : never;
type Paths<O, P = ""> = O extends object
  ? {
      [K in keyof O]:
        | (P extends "" ? K : `${SafeKey<P>}.${SafeKey<K>}`)
        | Paths<O[K], P extends "" ? K : `${SafeKey<P>}.${SafeKey<K>}`>;
    }[keyof O]
  : never;

const i18nInstance = ref<i18n>(
  new i18n({
    currentLanguageSet: enLanguageSet,
    fallbackLanguageSet: enLanguageSet,
  }),
);

export function t(
  key: Paths<typeof enLanguageSet>,
  params: Record<string, number | string> = {},
) {
  return unref(computed(() => i18nInstance.value.translate(key, params)));
}

export async function setCurrentLanguage(language: string) {
  const languageSet = await import(`./languages/${language}.json`);
  i18nInstance.value = new i18n({
    currentLanguageSet: languageSet,
    fallbackLanguageSet: enLanguageSet,
  });
}
