import { defineStore } from "pinia";
import type { ILoyaltyProgramStore } from "./types";

export const loyaltyProgramId = "loyaltyProgram";

export const useLoyaltyProgramStore = defineStore(loyaltyProgramId, {
  state: (): ILoyaltyProgramStore => ({
    isEnabled: false,
    expirationDays: 0,
    orderAmountPercentageWorth: 0,
    maximumRedeemedOrderAmount: 0,
    maximumRedeemedOrderPercentage: 0,
    signupPoints: 0,
  }),

  actions: {
    enable() {
      this.isEnabled = true;
    },
    disable() {
      this.isEnabled = false;
    },
    setLoyaltyProgram(newState: Omit<ILoyaltyProgramStore, "isEnabled">) {
      this.$patch({ ...newState, isEnabled: true });
    },
  },
});
