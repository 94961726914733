import type {
  IRepoFetchCustomerCardsRequest,
  IRepoFetchCustomerCardsResponse,
  IRepoFetchCustomerCardsContract,
} from "./types";
import type { ResponsePromise } from "ky";
import { http, repoBuildAndReportError } from "@/core/repo-utils";

const repoName = "repoFetchCustomerCards";

export async function repoFetchCustomerCards(
  request: IRepoFetchCustomerCardsRequest,
): Promise<IRepoFetchCustomerCardsResponse> {
  let response: IRepoFetchCustomerCardsContract;
  let rawResponse: ResponsePromise | undefined;

  try {
    const rawResponse = await http().get(
      `api/v1/customers/cards?business_id=${request.businessId}`,
    );

    response = await rawResponse.json();

    return response.data.map((card) => ({
      id: card.id,
      last4: card.last4,
      lastUsedAt: card.lastUsedAt ? new Date(card.lastUsedAt) : undefined,
      name: card.paymentMethod.toLowerCase(),
    }));
  } catch (err) {
    throw await repoBuildAndReportError(repoName, rawResponse, err);
  }
}
