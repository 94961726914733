import type {
  IAppRepoFetchBusinessContract,
  IAppRepoFetchBusinessRequest,
  IAppRepoFetchBusinessResponse,
} from "./types";
import { HTTPError, type ResponsePromise } from "ky";
import {
  http,
  repoBuildAndReportError,
  RepoKnownError,
} from "@/core/repo-utils";

const repoName = "appRepoFetchBusiness";

export async function appRepoFetchBusiness(
  request: IAppRepoFetchBusinessRequest,
): Promise<IAppRepoFetchBusinessResponse> {
  let response: IAppRepoFetchBusinessContract;
  let rawResponse: ResponsePromise | undefined;

  try {
    const rawResponse = await http().get(
      `storefront/business-by-id/${request.businessId}`,
    );

    response = await rawResponse.json();

    return {
      id: response.id,
      name: response.name,
      slug: response.slug,
      logo: response.logo ?? undefined,
      coverImage: response.cover_image ?? undefined,
      locale: response.locale,
      country: response.country,
      currency: response.currency,
      defaultMessagingChannel:
        response.default_messaging_channel === "whatsapp" ? "whatsapp" : "sms",
      isMenuGridDefault: response.is_menu_grid_default,
    };
  } catch (err) {
    if (err instanceof HTTPError && err.response.status == 404) {
      throw new RepoKnownError(
        repoName,
        "business_not_found",
        "The business provided is not valid.",
      );
    }

    if (err instanceof HTTPError && err.response.status == 422) {
      const errorMessage = (await err.response.json())?.errors?.business_id;

      if (errorMessage?.includes("The business id must be a valid UUID.")) {
        throw new RepoKnownError(
          repoName,
          "business_not_found",
          "The business provided is not valid.",
        );
      }
    }

    throw await repoBuildAndReportError(repoName, rawResponse, err);
  }
}
