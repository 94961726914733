import type {
  IAppRepoFetchGatewayConfigRequest,
  IAppRepoFetchGatewayConfigResponse,
} from "./types";
import type { ResponsePromise } from "ky";
import { http, repoBuildAndReportError } from "@/core/repo-utils";

const repoName = "appRepoFetchGatewayConfig";

export async function appRepoFetchGatewayConfig(
  request: IAppRepoFetchGatewayConfigRequest,
): Promise<IAppRepoFetchGatewayConfigResponse> {
  let rawResponse: ResponsePromise | undefined;

  try {
    const rawResponse = await http().get(
      `storefront/businesses/${request.businessId}/gateway-account`,
    );

    return await rawResponse.json();
  } catch (err) {
    throw await repoBuildAndReportError(repoName, rawResponse, err);
  }
}
