/**
 * Represents an error that may happen due to malformed JSON
 * or unexpected or empty responses being sent from the server
 */
export class RepoUnexpectedResponseError extends Error {
  constructor(repoName: string) {
    super(`${repoName} - Request resulted in unexpected or empty response`);
    this.name = "RepoUnexpectedResponseError";
  }
}
