/**
 * Represents an error that by connection issues such
 * as no internet or CORS
 */
export class RepoConnectionError extends Error {
  constructor(repoName: string, error: any) {
    super(error.message);
    const errorName = `RepoConnectionError::${this.name}`;
    this.message = `${repoName} - ${this.message} - Connection or CORS error occurred`;
    this.stack = error.stack ? error.stack.replace(this.name, errorName) : "";
    this.name = errorName;
  }
}
