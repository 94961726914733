import type {
  IRepoFetchCustomerContract,
  IRepoFetchCustomerResponse,
} from "./types";
import type { ResponsePromise } from "ky";
import { http, repoBuildAndReportError } from "@/core/repo-utils";

const repoName = "repoFetchCustomer";

export async function repoFetchCustomer(): Promise<IRepoFetchCustomerResponse> {
  let response: IRepoFetchCustomerContract;
  let rawResponse: ResponsePromise | undefined;

  try {
    const rawResponse = await http().get(`api/v1/customers/me`);

    response = await rawResponse.json();

    return {
      id: response.id,
      countryCode: response.country_code,
      phoneNumber: response.phone_number,
      email: response.email ?? undefined,
      name: response.name ?? undefined,
    };
  } catch (err) {
    throw await repoBuildAndReportError(repoName, rawResponse, err);
  }
}
