import { RouteNameEnum } from "@/router/route-name.enum";
import type { RouteLocationNormalized } from "vue-router";

export const guestOnly = (
  to: RouteLocationNormalized,
  hasAccessToken: boolean,
) => {
  if (to.meta.guestOnly && hasAccessToken) {
    return {
      name: RouteNameEnum.ROOT,
      params: to.params,
    };
  }
};
