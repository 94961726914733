import { listenFromTab, unlistenFromTab } from "@/core/broadcasting";
import { repoFetchOpenTabFromSpot } from "@/repos/fetch-open-tab-from-spot";
import { defineStore } from "pinia";
import type { ISpotStore } from "./types";

export const spotId = "spot";

export const useSpotStore = defineStore(spotId, {
  state: (): ISpotStore => ({
    id: "",
    name: "",
    openTab: undefined,
  }),
  getters: {
    hasOpenTab(): boolean {
      return Boolean(this.openTab?.id);
    },
  },
  actions: {
    setSpot(newState: ISpotStore) {
      this.id = newState.id;
      this.name = newState.name;
    },
    setOpenTab(tab: ISpotStore["openTab"]) {
      if (!tab) {
        return this.unsetOpenTab();
      }

      this.$patch({
        openTab: tab,
      });

      listenFromTab(tab.id, ["tabs.closed"], this.unsetOpenTab);
    },
    unsetOpenTab() {
      if (this.openTab) {
        unlistenFromTab(this.openTab.id, ["tabs.closed"], this.unsetOpenTab);
      }

      this.$patch({
        openTab: undefined,
      });
    },
    async syncOpenTab() {
      const tab = await repoFetchOpenTabFromSpot({ spotId: this.id });

      if (!tab) {
        this.unsetOpenTab();
        return;
      }

      this.setOpenTab({
        id: tab.id,
        owner: tab.owner,
        joiners: tab.joiners,
      });
    },
  },
});
