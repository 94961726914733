export enum RouteNameEnum {
  AUTH = "auth",
  CHECKOUT = "checkout",
  RECENT_ORDERS = "recent-orders",
  ITEM = "item",
  SINGLE_MENU = "single-menu",
  ROOT = "root",
  OTP = "otp",
  TAB = "tab",
  PROFILE = "profile",
  SELECT_SPOT = "select-spot",
}
