import type {
  IAppRepoFetchServiceChargeContract,
  IAppRepoFetchServiceChargeRequest,
  IAppRepoFetchServiceChargeResponse,
} from "./types";
import { HTTPError, type ResponsePromise } from "ky";
import { http, repoBuildAndReportError } from "@/core/repo-utils";

const repoName = "appRepoFetchServiceCharge";

export async function appRepoFetchServiceCharge(
  request: IAppRepoFetchServiceChargeRequest,
): Promise<IAppRepoFetchServiceChargeResponse> {
  let response: IAppRepoFetchServiceChargeContract;
  let rawResponse: ResponsePromise | undefined;

  try {
    const rawResponse = await http().get(
      `storefront/outlets/${request.outletId}/services/dine-in/generic-service-charge`,
    );

    response = await rawResponse.json();

    return response.value;
  } catch (err) {
    if (err instanceof HTTPError && err.response.status === 404) {
      return 0;
    } else {
      throw await repoBuildAndReportError(repoName, rawResponse, err);
    }
  }
}
