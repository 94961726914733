import { ExpireInMinutesEnum } from "@/stores/plugins/persisted-state";
import { defineStore } from "pinia";
import type { IOrderStore } from "./types";

export const storeName = "order";

export const useOrderStore = defineStore(storeName, {
  persist: true,
  expireInMinutes: ExpireInMinutesEnum.DAY,
  state: (): IOrderStore => ({
    orders: [],
  }),
});
