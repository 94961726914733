import { defineStore } from "pinia";
import type { IUiStore } from "./types";

export const uiId = "ui";

export const useUiStore = defineStore(uiId, {
  state: (): IUiStore => {
    const initialState: IUiStore = {
      viewState: "ready",
      errorStatus: null,
      errorMessage: "",
      isSingleMenu: undefined,
    };

    return initialState;
  },

  actions: {
    markViewAsReady() {
      this.viewState = "ready";
    },
    markViewAsLoading() {
      this.viewState = "loading";
    },
    markViewAsError(error: IUiStore["errorStatus"], message?: string) {
      this.viewState = "error";
      this.errorStatus = error;
      this.errorMessage = message ?? "";
    },
    markAsSingleMenu() {
      this.isSingleMenu = true;
    },
    markAsMultiMenu() {
      this.isSingleMenu = false;
    },
  },
});
