import type {
  PiniaPluginContext,
  StateTree,
  SubscriptionCallbackMutation,
} from "pinia";
import type { ILocalStorageData } from "./types";
import {
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
  saveItemInLocalStorage,
} from "@/core/utils";

export function createPersistedState(context: PiniaPluginContext) {
  const {
    options: { persist, expireInMinutes },
    store,
  } = context;

  if (!persist) return;

  const storeId = store.$id;

  store.startPersistence = function (key: string) {
    store.$state.key = key;

    const localSate = getItemFromLocalStorage<ILocalStorageData>(
      `${storeId}.${key}`,
    );

    if (!localSate) {
      return;
    }

    if (localSate.expireIn && new Date().getTime() > localSate.expireIn) {
      removeItemFromLocalStorage(`${storeId}.${key}`);
      return;
    }

    store.$patch(localSate.data);
  };

  store.$subscribe(
    (_mutation: SubscriptionCallbackMutation<StateTree>, state: StateTree) => {
      const expireAtDefault = expireInMinutes
        ? new Date().getTime() + Number(expireInMinutes) * 60000
        : null;

      const data: ILocalStorageData = {
        data: state,
        expireIn: expireAtDefault,
      };

      saveItemInLocalStorage(`${storeId}.${store.$state.key}`, data);
    },
    { detached: true, deep: true },
  );
}
