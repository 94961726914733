import { repoFetchCustomer } from "@/repos/fetch-customer";
import { repoFetchCustomerCards } from "@/repos/fetch-customer-cards";
import { repoFetchCustomerLoyaltyPoints } from "@/repos/fetch-customer-loyalty-points";
import { defineStore } from "pinia";
import type { ICustomerStore } from "./types";

export const storeName = "customer";

export const useCustomerStore = defineStore(storeName, {
  state: (): ICustomerStore => ({
    id: "",
    countryCode: "",
    phoneNumber: "",
    name: "",
    email: "",
    loyaltyBalance: { value: 0, currency: "AED" },
    cards: [],
  }),

  getters: {
    fullPhoneNumber(): string {
      return `+${this.countryCode}${this.phoneNumber}`;
    },
  },

  actions: {
    setCustomer(customer: Omit<ICustomerStore, "loyaltyBalance" | "cards">) {
      this.$patch({
        id: customer.id,
        countryCode: customer.countryCode,
        phoneNumber: customer.phoneNumber,
        email: customer.email,
        name: customer.name,
      });
    },
    async syncCards(businessId: string) {
      const cards = await repoFetchCustomerCards({
        businessId,
      });

      this.$patch({ cards });
    },
    async syncCustomer() {
      const customer = await repoFetchCustomer();
      this.setCustomer(customer);
    },
    async syncLoyaltyPoints(businessId: string, currency: string) {
      const balance = await repoFetchCustomerLoyaltyPoints({
        businessId,
        currency,
      });
      this.loyaltyBalance = balance;
    },
    async syncState(businessId: string, currency: string) {
      await Promise.all([
        this.syncCards(businessId),
        this.syncCustomer(),
        this.syncLoyaltyPoints(businessId, currency),
      ]);
    },
  },
});
