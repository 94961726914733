import type {
  IRepoFetchTabOutletSettingsRequest,
  IRepoFetchTabOutletSettingsContract,
  IRepoFetchTabOutletSettingsResponse,
} from "./types";
import type { ResponsePromise } from "ky";
import { http, repoBuildAndReportError } from "@/core/repo-utils";

const repoName = "repoFetchTabOutletSettings";

export async function repoFetchTabOutletSettings(
  request: IRepoFetchTabOutletSettingsRequest,
): Promise<IRepoFetchTabOutletSettingsResponse> {
  let response: IRepoFetchTabOutletSettingsContract;
  let rawResponse: ResponsePromise | undefined;

  try {
    const rawResponse = await http().get(
      `tabs/outlet/${request.outletId}/settings`,
    );

    response = await rawResponse.json();

    return {
      cardRequiredToStartTab: response.card_required_to_start_tab,
    };
  } catch (err) {
    throw await repoBuildAndReportError(repoName, rawResponse, err);
  }
}
