import type { ResponsePromise } from "ky";
import { http, repoBuildAndReportError } from "@/core/repo-utils";
import type {
  IRepoFetchCustomerLoyaltyPointsRequest,
  IRepoFetchCustomerLoyaltyPointsContract,
  IRepoFetchCustomerLoyaltyPointsResponse,
} from "./types";

const repoName = "repoFetchCustomerLoyaltyPoints";

export async function repoFetchCustomerLoyaltyPoints(
  request: IRepoFetchCustomerLoyaltyPointsRequest,
): Promise<IRepoFetchCustomerLoyaltyPointsResponse> {
  let response: IRepoFetchCustomerLoyaltyPointsContract;
  let rawResponse: ResponsePromise | undefined;

  try {
    const rawResponse = await http().get(
      `api/v1/customers/rewardPoints?business=${request.businessId}`,
    );

    response = await rawResponse.json();

    return {
      value: response.balance,
      currency: request.currency,
    };
  } catch (err) {
    throw await repoBuildAndReportError(repoName, rawResponse, err);
  }
}
