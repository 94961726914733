import { createRouter, createWebHistory } from "vue-router";
import { middlewares } from "./middlewares";
import { RouteNameEnum } from "./route-name.enum";
import { routes } from "./routes";

declare module "vue-router" {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface RouteMeta {
    themeColor?: string;
    guestOnly?: boolean;
    requiresAuth?: boolean;
  }
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(_, from, savedPosition) {
    // the item page is a drawer over the menu
    // it should not mess with the scroll when closed
    if (from.name === RouteNameEnum.ITEM) {
      return;
    }

    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach(middlewares);

export default router;
