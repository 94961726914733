import type { RouteRecordRaw } from "vue-router";
import { multiMenuOnly } from "./middlewares/multi-menu-only";
import { RouteNameEnum } from "./route-name.enum";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/:outletSlug",
    name: RouteNameEnum.SELECT_SPOT,
    props: true,
    component: async () => import("../views/select-spot/SelectSpot.vue"),
  },
  {
    path: "/:outletSlug/:spotId",
    name: RouteNameEnum.ROOT,
    props: true,
    component: async () => import("../views/menu/menu-list/MenuList.vue"),
    beforeEnter: [multiMenuOnly],
  },
  {
    path: "/:outletSlug/:spotId/menu/:menuSlug?",
    name: RouteNameEnum.SINGLE_MENU,
    props: true,
    component: async () => import("../views/menu/single-menu/SingleMenu.vue"),
    children: [
      {
        path: "item/:itemId",
        name: RouteNameEnum.ITEM,
        props: true,
        components: {
          drawer: async () => import("../views/item/ItemView.vue"),
        },
      },
    ],
  },
  {
    path: "/:outletSlug/:spotId/profile",
    name: RouteNameEnum.PROFILE,
    props: true,
    component: async () => import("../views/profile/ProfileView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:outletSlug/:spotId/auth",
    name: RouteNameEnum.AUTH,
    props: true,
    component: async () => import("../views/auth/AuthView.vue"),
    meta: {
      guestOnly: true,
    },
  },
  {
    path: "/:outletSlug/:spotId/tab/:tabId",
    name: RouteNameEnum.TAB,
    props: true,
    component: async () => import("../views/tab/TabView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:outletSlug/:spotId/otp",
    name: RouteNameEnum.OTP,
    props: true,
    component: async () => import("../views/otp/OtpView.vue"),
    meta: {
      guestOnly: true,
    },
  },
  {
    path: "/:outletSlug/:spotId/checkout",
    name: RouteNameEnum.CHECKOUT,
    props: true,
    component: async () => import("../views/checkout/CheckoutView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:outletSlug/:spotId/_checkout",
    props: true,
    component: async () => import("../views/checkout/_next/CheckoutView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:outletSlug/:spotId/recent-orders",
    name: RouteNameEnum.RECENT_ORDERS,
    props: true,
    component: async () => import("../views/orders/OrdersView.vue"),
  },
];
