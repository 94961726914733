import { report } from "@chatfood/bug-reporter";
import { HTTPError, type ResponsePromise } from "ky";
import { buildRepoErrorContext } from "./build-repo-error-context";
import { RepoConnectionError } from "./connection-error";
import { RepoProcessError } from "./process-error";
import { RepoError } from "./repo-error";
import { RepoUnexpectedResponseError } from "./unexpected-response-error";
import { RepoUnknownError } from "./unknown-error";

export async function repoBuildAndReportError(
  repoName: string,
  rawResponse: ResponsePromise | undefined,
  err: unknown,
) {
  let responseText = "";

  if (rawResponse) {
    responseText = await rawResponse.text();
  }

  if (err instanceof TypeError && err.message.includes("Failed to fetch")) {
    const repoConnectionError = new RepoConnectionError(repoName, err);
    report(repoConnectionError);
    return repoConnectionError;
  }

  if (err instanceof HTTPError) {
    const repoError = new RepoError(repoName, err);
    report(repoError, await buildRepoErrorContext(repoError));
    return repoError;
  }

  if (err instanceof SyntaxError) {
    const repoProcessError = new RepoProcessError(repoName, err);
    report(repoProcessError, {
      context: {
        responseText,
      },
    });
    return repoProcessError;
  }

  if (err instanceof RepoUnexpectedResponseError) {
    report(err, {
      context: {
        responseText,
      },
    });

    return err;
  }

  /**
   * Throw any remaining unknown / unhandled errors that
   * don't fall into the above categories
   * just report and return them back for
   * handling
   */

  const repoUnknownError = new RepoUnknownError(repoName, err);
  report(repoUnknownError, {
    context: {
      responseText,
    },
  });

  return repoUnknownError;
}
