import ky from "ky-universal";
import { getItemFromLocalStorage } from "@/core/utils";
import { LocalStorageKeys } from "@/core/types";

export const httpNetworkOnly = ky.extend({
  prefixUrl: import.meta.env.VITE_APP_API_URL,
  retry: 0,
  headers: {
    accept: "application/json",
    "content-type": "application/json",
  },
  hooks: {
    beforeRequest: [
      (request: Request) => {
        const accessToken = getItemFromLocalStorage<string>(
          LocalStorageKeys.AUTH_TOKEN,
        );

        if (accessToken) {
          request.headers.set("Authorization", accessToken);
        }
      },
    ],
  },
});
