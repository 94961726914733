import { HTTPError } from "ky";

/**
 * Represents an HTTP error and attempts to preserve
 * all associated meta data
 */
export class RepoError extends HTTPError {
  constructor(repoName: string, err: HTTPError) {
    super(err.response, err.request, err.options);
    this.name = "RepoError";
    this.message = `${repoName} - ${this.message}`;
    this.stack = err.stack
      ? err.stack.replace("HTTPError", "RepoError::HTTPError")
      : "";
  }
}
