import type { IMessagingChannel } from "@/core/types";
import { parseInputNumber } from "@chatfood/core-utils";
import { defineStore } from "pinia";
import type { IAuthStore } from "./types";

export const storeName = "auth";

export const useAuthStore = defineStore(storeName, {
  state: (): IAuthStore => ({
    countryCode: "",
    countryIso: "",
    messagingChannel: "sms",
    phoneNumber: "",
    channelChanged: false,
    otp: "",
    redirectToOnSuccess: undefined,
  }),

  actions: {
    setPendingAuthentication(authData: IAuthStore) {
      this.countryCode = authData.countryCode;
      this.countryIso = authData.countryIso;
      this.phoneNumber = authData.phoneNumber;
      this.messagingChannel = authData.messagingChannel;
      this.otp = authData.otp;
      this.redirectToOnSuccess = authData.redirectToOnSuccess;
    },
    resetPendingAuthentication() {
      this.$reset();
    },
    setOtp(otp: string) {
      this.otp = otp;
    },
    setMessagingChannel(messagagingChannel: IMessagingChannel) {
      this.messagingChannel = messagagingChannel;
    },
    toggleChannelChangedStatus(status: boolean) {
      this.channelChanged = status;
    },
    setRedirectToOnSuccessRoute(routePath: string | undefined) {
      this.redirectToOnSuccess = routePath;
    },
  },
  getters: {
    getReadablePhoneNumber(): string {
      return parseInputNumber(`+${this.countryCode}${this.phoneNumber}`).number
        .international;
    },
  },
});
