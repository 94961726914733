import { createApp } from "vue";
import { createPinia } from "pinia";

import "@chatfood/design-system/dist/style.css";

import router from "./router";
import { reportInit } from "@chatfood/bug-reporter";
import { AppLayout } from "./layouts/app";
import { createPersistedState } from "./stores/plugins/persisted-state";

const app = createApp(AppLayout);

reportInit({
  app,
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  environment: import.meta.env.VITE_APP_ENV,
  debug: import.meta.env.VITE_APP_SENTRY_DEBUG === "true",
  enabled: import.meta.env.VITE_APP_SENTRY_ENABLED === "true",
  release: import.meta.env.VITE_APP_VERSION,
  jsFramework: "Vue 3",
  browserTracing: {
    router,
    origins: [/^\//],
  },
});

const pinia = createPinia();
pinia.use(createPersistedState);

app.use(pinia);
app.use(router);

app.mount("#app");

window.__APP_VERSION__ = import.meta.env.VITE_APP_VERSION;
