import type {
  IRepoFetchOutletPaymentMethodsContract,
  IRepoFetchOutletPaymentMethodsRequest,
  IRepoFetchOutletPaymentMethodsResponse,
} from "./types";
import type { ResponsePromise } from "ky";
import { http, repoBuildAndReportError } from "@/core/repo-utils";

const repoName = "repoFetchOutletPaymentMethods";

const methodMap: Record<
  IRepoFetchOutletPaymentMethodsContract["data"][0],
  IRepoFetchOutletPaymentMethodsResponse[0]
> = {
  apple_pay: "applePay",
  card_delivery: "cardMachine",
  card_online: "cardOnline",
  cash_delivery: "cash",
  add_to_tab: "addToTab",
  deliverect_pay: "deliverectPay",
  skipcash: "skipcash",
};

export async function repoFetchOutletPaymentMethods(
  request: IRepoFetchOutletPaymentMethodsRequest,
): Promise<IRepoFetchOutletPaymentMethodsResponse> {
  let response: IRepoFetchOutletPaymentMethodsContract;
  let rawResponse: ResponsePromise | undefined;

  try {
    const rawResponse = await http().get(
      `storefront/enabled-payment-methods-of-outlet/${request.outletId}/dine-in`,
    );

    response = await rawResponse.json();

    return response.data.map((paymentMethod) => methodMap[paymentMethod]);
  } catch (err) {
    throw await repoBuildAndReportError(repoName, rawResponse, err);
  }
}
