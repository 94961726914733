<script setup lang="ts">
type IErrorViewProps = {
  status:
    | "not-found"
    | "internal-error"
    | "outlet-not-found"
    | "business-not-found"
    | "spot-not-found";
  message?: string;
}
import { AtomWrapper, AtomText, AtomGraphic, AtomImage } from '@chatfood/design-system'
import { css } from './css'
import { t } from '@/i18n'
import { computed } from 'vue'
import { useBusinessStore } from '@/stores'
const props = withDefaults(defineProps<IErrorViewProps>(), {
  status: "internal-error",
});
type IKeyType = IErrorViewProps["status"];
type IDefaultMessages = Partial<Record<IKeyType, string>>;
const defaultMessages = computed<IDefaultMessages>(() => {
  return {
    "outlet-not-found": t("view.error.outlet_not_found"),
    "not-found": t("view.error.page_not_found"),
    "business-not-found": t("view.error.business_not_found"),
    "internal-error": t("view.error.internal_error"),
    "spot-not-found": t("view.error.spot_not_found"),
  };
});
const message = computed(() => {
  if (props.message && props.message.length > 0) {
    return props.message;
  }
  return defaultMessages.value[props.status];
});
const businessStore = useBusinessStore();
</script>

<template>
  <AtomWrapper :class="css()">
    <div :class="css('content')">
      <div :class="css('icon')">
        <AtomImage
          v-if="businessStore.logo"
          :size="100"
          :src="`${businessStore.logo}?fm=jpeg&h=180&w=180&q=90&fit=crop&crop=center`"
          :alt="businessStore.name"
          type="rounded"
        />
        <AtomGraphic v-else name="chatfood-logo" :size="100" />
      </div>
      <AtomText :class="css('headline')" color="color-primary" type="h1">
        {{ t("view.error.headline") }}
      </AtomText>
      <AtomText :class="css('message')" color="color-base-40">
        {{ message }}
      </AtomText>
      <AtomText :class="css('message')" type="body-bold" color="color-base-30">
        {{ t("view.error.scan_qrcode_message") }}
      </AtomText>
    </div>
  </AtomWrapper>
</template>

<style lang="scss">
.error-view {
  position: relative;

  &__content {
    flex: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-on-primary);
    justify-content: center;
    padding: var(--space-21) 0;
    gap: var(--space-28);
  }

  &__message {
    padding: 0 var(--space-28);
    text-align: center;
  }
}
</style>
