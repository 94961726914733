import type { KyInstance } from "ky/distribution/types/ky";
import type { IRepoCacheConfig } from "../types";
import { httpNetworkOnly } from "./http-network-only";
import { repoCache } from "./repo-cache";

export const httpWithCache = ({ cacheTTL = 0 }: IRepoCacheConfig): KyInstance =>
  httpNetworkOnly.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          if (request.method === "GET") {
            // before every request purge the cache from unncessary data
            repoCache.purgeStale();

            // retrieve the cached data as copy
            const cached = repoCache.get(request.url)?.clone();
            if (cached && !cached.bodyUsed) {
              return cached;
            }
          }
        },
      ],
      afterResponse: [
        (_input, _options, response) => {
          if (
            _input.method !== "GET" ||
            (response.status >= 400 && response.status < 600)
          ) {
            return response;
          }

          // fetch the cached response
          const cached = repoCache.get(response.url);
          if (!cached) {
            repoCache.set(response.url, response.clone(), {
              ttl: cacheTTL * 1000,
            });
          }

          return response;
        },
      ],
    },
  });
