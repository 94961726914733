import type { IBasketModifier } from "./types";
import type { IPrice } from "@/core/types";

export function stringToHash(value: string) {
  let hash = 0,
    i,
    chr;
  if (value.length === 0) return hash;
  for (i = 0; i < value.length; i++) {
    chr = value.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  // always return positive numbers
  return hash + 2147483647 + 1;
}

export function arrayToHash(
  modifiers: Array<IBasketModifier> | undefined,
): string {
  if (modifiers && modifiers.length > 0) {
    return stringToHash(
      modifiers.flatMap((item) => `${item.id}:${item.price.value}`).join("|"),
    ).toString();
  }
  return "0";
}

export function generateLineId(
  itemId: string,
  price: number,
  modifiers?: Array<IBasketModifier> | undefined,
): string {
  return `${itemId}.${stringToHash(price.toString())}.${arrayToHash(
    modifiers,
  )}`;
}

export function calculateTotalItemPrice(
  itemPrice: IPrice,
  modifiers: IBasketModifier[] = [],
) {
  return {
    value: itemPrice.value + calculateTotalModifiersValue(modifiers),
    currency: itemPrice.currency,
  };
}

function calculateTotalModifiersValue(modifiers: IBasketModifier[] = []) {
  return modifiers.reduce((total, modifier) => {
    const quantity = modifier.quantity;
    const price = modifier.price.value;

    let totalModifier = price;

    if (modifier?.modifiers?.length) {
      totalModifier += modifier.modifiers.reduce(
        (acc, combo) => (acc += combo.price.value * combo.quantity),
        0,
      );
    }

    return totalModifier * quantity + total;
  }, 0);
}
