export type IPrice = {
  value: number;
  currency: string;
};

export type IPaymentMethod = {
  id: string;
  name: string;
  last4: string;
};

export type IIntervalType = ReturnType<typeof setInterval> | null;
export type IMessagingChannel = "whatsapp" | "sms";

export enum LocalStorageKeys {
  "AUTH_TOKEN" = "auth._token.local",
}

export type IRepoCacheConfig = {
  cacheTTL?: number;
};
